@import "minima";


.mb-10 {
    margin-bottom: 10px;
}


.mb-30 {
    margin-bottom: 30px;
}

.mt-60 {
    margin-top: 60px;
}

.pb-30 {
    padding-bottom:30px;
}

.border-bottom {
    border-bottom: 1px solid #dee2e6;
}

.border-bottom:last-child {
    border-bottom: none;
}

.padding-small {
    padding: 1px .45em;
}

.padding-medium {
    padding: 3px .5em;
}

.padding-large {
    padding: .25em 1em;
}

.grey-dark {
    color: rgba(14,30,37,.8);
}

/* post title */
.grey-darker {
    color: #0e1e25 !important;
}

/* post excerpt, post-content */
.grey-light {
    color: rgba(14, 30, 37, 0.7);
}

/* post meta */
.grey-ligther {
    color: #828282;
}

.fsize-small {
    font-size: 16px;
}

.fsize-smaller {
    font-size: 14px;
}

.fsize-large {
    font-size: 1em;
}

.fsize-larger {
    font-size: 1.5em;
}

.ls-1 {
    letter-spacing: -1px;
}

.post-title {
    color: #0e1e25 !important;
    font-size: 2.1rem;
    letter-spacing: -1px;
    font-weight: 600;
    line-height: 1.2;
}

.d-block {
    display: block;
}

.d-inline-block {
    display: inline-block;
}

.lh-16 {
    line-height: 1.6;
}

.lh-12 {
    line-height: 1.2;
}

.bg-blue {
    background-color: #0085d5;
}

.text-small {
    font-weight: 400;
}

.text-smaller {
    font-weight: 300;
}

.text-bold {
    font-weight: 600;
}

.text-bolder {
    font-weight: 700;
}

.border, .border-alt {
    border: 1px solid #d9d9d9;
    color: #586069 !important;
    box-shadow: 0 0 3px 0 #b6bfc8;
    border-radius: .25em;

}

.border:hover, .border-alt:target{
    background-color: #0085d5;
    border-color: #0085d5;
    text-decoration: none;
    color: #fff !important;
}

.previous {
    float: left;
}

.next {
    float: right;
}

/* Site description */
.footer-col-3 {
    width: -webkit-calc(50% - (30px / 2));
    width: calc(50% - (30px / 2));
}

.footer-link {
    margin-left: 0;
    padding-left: 15px;
    list-style-type: circle;
}

/* déplacer en bas avec le reste des options de form */
.contact-form {
    margin: 2rem 0 2rem 0;
}

.tree {
    margin: 0 0 0 1em;
    list-style: none;
    color: #b7b7b7;
    position: relative;
    margin-bottom: 1.5em;
}

.tree:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-left: 1px solid;
}

.tree li {
    padding: 0 1.5em;
    line-height: 2em;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tree li:before {
    content: '';
    display: block;
    width: 10px;
    border-top: 1px solid;
    position: absolute;
    top: 1em;
    left: 0;
}

.tree li:last-child:before {
    background: #fff;
    height: auto;
    top: 1em;
    bottom: 0;
}

.form-control {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    display: block;
    width: 95%;
    max-width: 500px;
    padding-left: .5rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    border: 1px solid #ced4da;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
}

#form-name, #form-email {
    height: 2.5rem;
}

#form-message {
    height: 8rem;
    padding-top: .5rem;
}

#form-submit {
    height: 2rem;
    width: 6rem;
    background: #0085d5;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    font-size: .9rem;
    border: none;
    border-radius: .25rem;
    font-weight: bolder;
}
